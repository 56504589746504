<template>
  <b-badge class="w-100" :variant="color(params.value)">
    {{changesName(params.value)}}
  </b-badge>
</template>

<script>

import techServiceModule from '@/store/tech-service/techServiceModule'
import { BBadge } from 'bootstrap-vue'
import store from '@/store'

export default {
  name: 'cell-renderer-changes',
  components: {
    BBadge
  },
  data () {
    return {
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
    }
  },
  computed: {
    changes () {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].changes
    },
    changesName () {
      return (value) => {
        const index = this.changes.findIndex((e) => e.value === value)
        if (index >= 0) {
          return this.changes[index].label
        }
        return value
      }
    },
    color () {
      return (value) => {
        return (value) ? 'danger' : 'success'
      }
    }
  },
  created() {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
  },
}
</script>
