<template>
  <div>
    <template v-if="userType !== 'TECHNICAL_SERVICE_OPERATOR'">
      <div v-if="params.data.Opinion" :class="`radarIcon ${(can_edit(params)) ? 'warning' : 'success'}`">
        <span></span>
      </div>
      <div v-else class="radarIcon danger"><span></span></div>
    </template>
    <b-badge :class="badgeColClass" :variant="color(params)">
      <template v-if="userType === 'TECHNICAL_SERVICE_OPERATOR'">
        <template v-if="can_edit(params)">
          <vue-countdown :time="expire_time(params)" v-slot="{ days, hours, minutes, seconds }">
            <span v-if="days > 0">{{days}} օր․</span>
            {{ hours }} ժ․ {{ minutes }} ր․ {{ seconds }} վ․
          </vue-countdown>
        </template>
        <template v-else>
          {{ editContent(params) }}
        </template>
      </template>
      <template v-else>
        <div v-if="params.data.Opinion">
          <template v-if="can_edit(params)">
            Ստուգվում է
          </template>
          <template v-else>
            Ստուգված է
          </template>
        </div>
        <div v-else>
          <template v-if="userType === 'ADMIN' || userType === 'VIEWER'">
            <template v-if="can_edit(params)">
              <vue-countdown :time="expire_time(params)" v-slot="{ days, hours, minutes, seconds }">
                <span v-if="days > 0">{{days}} օր․</span>
                {{ hours }} ժ․ {{ minutes }} ր․ {{ seconds }} վ․
              </vue-countdown>
            </template>
            <template v-else>
              {{ editContent(params) }}
            </template>
          </template>
          <template v-else>
            Ժամկետանց
          </template>

        </div>

      </template>

    </b-badge>
    <fa-icon @click="openClassificatorDoc(params.data)" v-if="userType === 'ADMIN' || userType === 'TECHNICAL_SERVICE_OWNER' || userType === 'VIEWER'" :class="`ml-1 printCol ${params.data.Changes ? 'hasNote' : ''}`" icon="print" />
    <template v-if="userType === 'ADMIN' && ['ruben.ordanyan@gmail.com', 'khamazaspyan@gmail.com'].includes(this.$store.state.user.email)">
      <fa-icon class="ml-1"  @click="removeItemSoft(params)" icon="trash-alt"/>
      <fa-icon class="ml-1" @click="editItem(params)" icon="edit"/>
    </template>
    <fa-icon @click="getNumbersInfo(params.data)" v-if="params.data.CheckNoEdited && userType !== 'TECHNICAL_SERVICE_OPERATOR'" class="ml-1" icon="paste"/>
    <fa-icon v-if="userType === 'ADMIN' || userType === 'TECHNICAL_SERVICE_OWNER' || userType === 'VIEWER'" @click="openHistory(params.data)" class="ml-1" icon="history" />
    <fa-icon v-if="userType === 'VIEWER'" @click="openCompanyInfo(params.data)" class="ml-1" icon="info-circle" />
  </div>

</template>

<script>

import { BBadge } from 'bootstrap-vue'
import VueCountdown from '@chenfengyuan/vue-countdown';
import store from '@/store'
import techServiceModule from '@/store/tech-service/techServiceModule'

export default {
  name: 'cell-renderer-can-edit',
  components: {
    BBadge,
    VueCountdown
  },
  data() {
    return {
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
    }
  },
  computed: {

    userType () {
      return atob(this.$store.state.user_type)
    },

    color () {
      return (item) => {
        if (!item.data.Opinion) {
          return 'danger'
        }
        else {
          const last_day = new Date(item.value)
          if (this.userType === 'TECHNICAL_SERVICE_OPERATOR') {
            const day = (item.data.Opinion) ? 1 : 20
            if (day === 20) {
              last_day.setDate(last_day.getDate() + day)
            } else {
              last_day.setMinutes(last_day.getDate() + 10)
            }
            if (day === 20) {
              last_day.setHours(23, 59, 59, 59)
            }
          }
          else {
            last_day.setMinutes(last_day.getMinutes() + 2)
          }
          const now = new Date(Date.now());
          let diff =(last_day.getTime() - now.getTime()) / 1000
          diff /= 60
          return (diff <= 0) ? 'success' : 'warning'
        }

      }
    },

    can_edit () {
      return (item) => {
        const last_day = new Date(item.value)
        if(item.data.Opinion) {
          if (this.userType === 'TECHNICAL_SERVICE_OPERATOR') {
            const day = (item.data.Opinion) ? 1 : 20
            if (day === 20) {
              last_day.setDate(last_day.getDate() + day)
            } else {
              last_day.setMinutes(last_day.getDate() + 10)
            }
            if (day === 20) {
              last_day.setHours(23, 59, 59, 59)
            }
          }
          else {
            last_day.setMinutes(last_day.getMinutes() + 2)
          }
        }
        else {
          if (this.userType === 'TECHNICAL_SERVICE_OPERATOR' || this.userType === 'ADMIN' || this.userType === 'VIEWER') {
            const day = (item.data.Opinion) ? 1 : 20
            if (day === 20) {
              last_day.setDate(last_day.getDate() + day)
            } else {
              last_day.setMinutes(last_day.getDate() + 10)
            }
            if (day === 20) {
              last_day.setHours(23, 59, 59, 59)
            }
          }
          else {
            last_day.setMinutes(last_day.getMinutes() + 2)
          }
        }


        const now = new Date(Date.now());
        let diff =(last_day.getTime() - now.getTime()) / 1000
        diff /= 60
        return (diff > 0)
      }
    },

    editContent () {
      return (value) => {
        return (!this.can_edit(value)) ? (value.data.Opinion) ? 'Հաստատվել է' : 'Ժամկետանց' :  this.$options.filters.formatDate(value);
      }
    },

    expire_time () {
      return (item) => {
        const last_day = new Date(item.value)
        const day = (item.data.Opinion) ? 1 : 20
        if (day === 20) {
          last_day.setDate(last_day.getDate() + day)
        } else {
          last_day.setMinutes(last_day.getDate() + 10)
        }

        if (day === 20) {
          // last_day.setHours(23, 59, 59, 59)
        }
        const now = new Date(Date.now());
        let diff =(last_day.getTime() - now.getTime())
        return diff
      }
    },

    badgeColClass () {
      if (this.userType === 'ADMIN' || this.userType === 'TECHNICAL_SERVICE_OWNER') {
        return 'badgeCol'
      }
      else if (this.userType === 'VIEWER') {
        return 'badgeColView'
      }
      else {
        return 'w-100'
      }
    }
  },
  created() {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
  },
  methods: {
    openClassificatorDoc (data) {
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_SELECTED_CHECKUP', data)
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/OPEN_DOC', 'classificator')
    },
    editItem(params) {
      const data = params.data
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/OPEN_EDIT_FORM', data)
    },
    removeItem(params) {
      this.$swal({
        title: 'Հեռացնե՞լ',
        text: '',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Այո',
        cancelButtonText: 'Ոչ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/removeCheckup', params.data)
                  .then((response) => {
                    // this.$socket.emit('OPERATOR_REMOVED_CHECKUP', response.data.item)
                  })
                  .catch(() => {
                    this.$swal({
                      title: 'Գործողությունը մերժվել է',
                      text: '',
                      icon: 'error',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    })
                  })
            }
          })
    },
    removeItemSoft(params) {
      this.$swal({
        title: 'Հեռացնե՞լ',
        text: '',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Այո',
        cancelButtonText: 'Ոչ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/removeCheckupSoft', params.data)
                  .then((response) => {
                    // this.$socket.emit('OPERATOR_REMOVED_CHECKUP', response.data.item)
                  })
                  .catch(() => {
                    this.$swal({
                      title: 'Գործողությունը մերժվել է',
                      text: '',
                      icon: 'error',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    })
                  })
            }
          })
    },
    getNumbersInfo (checkup) {
      this.$vs.loading()
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getCheckupNumbers', checkup._id)
          .then((response) => {
            this.$vs.loading.close()
            this.$bvModal.show('modal-add-c1-number')
          })
          .catch(error => {
            this.$vs.loading.close()
            this.$root.$emit('getError', error)
          })
    },

    openHistory (checkup) {
      this.$vs.loading()
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getCheckupHistory', checkup._id)
          .then(() => {
            this.$vs.loading.close()
            this.$bvModal.show('modal-checkup-history')
          })
          .catch(error => {
            this.$vs.loading.close()
            this.$root.$emit('getError', error)
          })
    },

    openCompanyInfo(checkup) {
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_CHECKUP_COMPANY', checkup)
      this.$bvModal.show('modal-checkup-company-info')
    }

  }
}
</script>
