<template>
  <b-badge class="w-100" :variant="color(params.value)">
    {{opinionName(params.value)}}
  </b-badge>
</template>

<script>

import techServiceModule from '@/store/tech-service/techServiceModule'
import { BBadge } from 'bootstrap-vue'
import store from '@/store'

export default {
  name: 'cell-renderer-opinion',
  components: {
    BBadge
  },
  data () {
    return {
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
    }
  },
  computed: {
    opinions () {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].opinions
    },
    opinionName () {
      return (value) => {
        const index = this.opinions.findIndex((e) => e.value === value)
        if (index >= 0) {
          return this.opinions[index].label
        }
        return value
      }
    },
    color () {
      return (value) => {
        return (value) ? 'success' : 'danger'
      }
    }
  },
  created() {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
  },
}
</script>
