<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <b-alert
        variant="danger"
        :show="invoiceData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
            class="alert-link"
            :to="{ name: 'apps-invoice-list'}"
        >
          Invoice List
        </b-link>
        for other invoices.
      </div>
    </b-alert>

    <b-row
        v-if="invoiceData"
        class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->

      <b-col
          cols="12"
          xl="9"
          md="8"
      >
        <b-card
            no-body
            class="invoice-preview-card"
        >
          <ClassificatorContent :invoiceData="invoiceData" :randomMechanic="randomMechanic" :bodies="bodies"/>

        </b-card>


        <div class="invoice-preview-card download" ref="document">
          <ClassificatorContent :invoiceData="invoiceData" :randomMechanic="randomMechanic" :bodies="bodies"/>
        </div>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
      >
        <b-card>

          <!-- Button: CLose -->
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mb-75"
              block
              @click="closeDoc"
          >
            <feather-icon
                icon="XCircleIcon"
                class="mr-50"
            />
            Փակել
          </b-button>

          <!-- Button: Send Invoice -->
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-75"
              block
              @click="generatePdf"
          >
            <feather-icon
                icon="DownloadIcon"
                class="mr-50"
            />
            <span>Բեռնել</span>
          </b-button>

          <!-- Button: Print -->
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="success"
              class="mb-75"
              block
              @click="printInvoice"
          >
            <feather-icon
                icon="PrinterIcon"
                class="mr-50"
            />
            <span>Տպել</span>
          </b-button>


        </b-card>
      </b-col>
    </b-row>

    <invoice-sidebar-send-invoice />
  </section>
</template>

<script>
import html2pdf from 'html2pdf.js'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import InvoiceSidebarSendInvoice from './InvoiceSidebarSendInvoice.vue'
import techServiceModule from '@/store/tech-service/techServiceModule'
import carsModule from '@/store/cars/cars'
import VueQrcode from 'vue-qrcode'
import VueHtml2pdf from 'vue-html2pdf'
import ClassificatorContent from '@/views/pages/tech-service/docs/content/ClassificatorContent'
export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    VueQrcode,
    Logo,
    InvoiceSidebarSendInvoice,
    VueHtml2pdf,
    ClassificatorContent
  },
  data () {
    return {
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
      CARS_STORE_MODULE_NAME: 'carsModule',
      randomMechanic: ''
    }
  },
  computed: {
    invoiceData () {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].selectedCheckup
    },
    company () {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].company
    },
    classificators() {
      return (this.invoiceData.newClassificator) ? this.$store.state[this.CARS_STORE_MODULE_NAME].new_classificators : this.$store.state[this.CARS_STORE_MODULE_NAME].classificators
    },
    double_checked_name () {
      return (value) => {
        return (value) ? 'Կրկնակի' : 'Սկզբնական'
      }
    },
    NextCheckupName () {
      return (value) => {
        switch (value) {
          case 0:
            return '6 ամսից'
          case 1:
            return '1 տարուց'
          case 2:
            return '2 տարուց'
        }
      }
    },
    checkbox_class () {
      return (id) => {
        return (this.invoiceData.classificators.includes(id)) ? 'checkbox active' : 'checkbox'
      }
    },
    bodies() {
      return this.$store.state[this.CARS_STORE_MODULE_NAME].bodies
    },
  },
  created() {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
    if (!store.hasModule(this.CARS_STORE_MODULE_NAME)) store.registerModule(this.CARS_STORE_MODULE_NAME, carsModule)
    this.getClassificators()
    this.getMechanics()
  },
  destroyed() {
    this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CLOSE_DOC', 'classificator')
  },
  methods: {
    getClassificators() {
      this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getClassificators')
      this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getNewClassificators')
    },

    getMechanics () {
      if (!this.invoiceData.mechanic) {
        this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getCompanyMechanic', this.invoiceData.company._id)
        .then((response) => {
          if (response.data.data) {
            if (response.data.data.name) {
              this.randomMechanic = response.data.data.name
            }
          }
        })
      }
    },

    closeDoc () {
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CLOSE_DOC', 'classificator')
    },

    generatePdf () {
      this.$refs.document.setAttribute('style', 'display: flex')
      html2pdf(this.$refs.document, {
        mode: ['css', 'legacy'],
        margin: 0,
        filename: 'document.pdf',
        image: { type: 'jpeg', quality: 1 },
        html2canvas:  { scale: 2, useCORS: true },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
      })
      .then(() => {
        this.$refs.document.setAttribute('style', 'display: none')
      })
    },

    bodyName (item) {
      if (item.body) {
        return item.body
      }
      else {
        let bodyIndex = this.bodies.findIndex((b) => b.id === item.body_id)
        if (bodyIndex >= 0) {
          return this.bodies[bodyIndex].name
        }
      }
    }
  },
  setup() {
    const invoiceData = ref(null)
    const paymentDetails = ref({})

    // Invoice Description
    // ? Your real data will contain this information
    const invoiceDescription = [
      {
        taskTitle: 'Native App Development',
        taskDescription: 'Developed a full stack native app using React Native, Bootstrap & Python',
        rate: '$60.00',
        hours: '30',
        total: '$1,800.00',
      },
      {
        taskTitle: 'UI Kit Design',
        taskDescription: 'Designed a UI kit for native app using Sketch, Figma & Adobe XD',
        rate: '$60.00',
        hours: '20',
        total: '$1200.00',
      },
    ]

    const printInvoice = () => {
      window.print()
    }

    return {
      paymentDetails,
      invoiceDescription,
      printInvoice,
    }
  },
}
</script>



<style lang="scss">
.invoice-spacing {
  border-top: 2px solid #000;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
@page {
  margin: 0!important;
}
.doc-header {
  position: relative;

  .qr-code {
    position: absolute;
    right: 0;
    top: -20px;
  }
}

.header-spacing {
  margin: 2rem 0!important;
}

.invoice-preview-card {
  max-height: calc(var(--vh, 1vh) * 100 - 10rem);
  min-height: calc(var(--vh, 1vh) * 100 - 10rem);
  overflow-y: scroll;

  * {
    color: #000
  }
  &.download {
    max-height: none;
    min-height: inherit;
    overflow-y: auto;
    font-size: 9px;
    display: none;
  }
}

.doc-footer {

  margin-top: 30px;

.notesContent {
  display: flex;
  flex-direction: column;
  .title {

  }
  .notes {
    border: 1px solid #000;
    min-height: 60px;
    padding: 5px;
  }
}
}

.classificatorsData {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  .watermark {
    position: absolute;
    top: 36%;
    left: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9rem;
    color: #eaeaea !important;
    font-weight: bold;
    opacity: 0.4;
    transform: rotate(
            -45deg
    );
  }
}

.classificators {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #000;
  border-bottom: 0;
  .classificator_list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .classificator {
    display: flex;
    width: 20%;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    min-height: 40px;
    justify-content: space-between;
    padding: 3px;

    &:nth-of-type(5n) {
      border-right: none;
    }

    .title {
      opacity: 1;
      .name {
        opacity: 1;
      }
    }

    .data {
      display: flex;
      align-items: flex-end;
      margin-bottom: 3px;

      .code {
        margin-right: 1px;
      }

      .checkbox {
        border: 1px solid #000;
        width: 20px;
        height: 20px;

        &.active {
          background-color: #000;
          -webkit-print-color-adjust: exact;
        }
      }
    }
  }
}

.signs {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 10px;

  .sign {
    display: flex;
    margin: 10px 0;
    padding: 0 15px;

    .title {
      width: 30%;
    }

    .space {
      width: 25%;
      border-bottom: 1px dashed;
      position: relative;
      top: -3px;
      margin: 0 35px;
    }
  }
}

@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        overflow: auto;
        height: auto;
        min-height: 0;
        max-height: none;
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
.invoice-preview-card {
  font-family: Arial, sans-serif;
}
@media print {
  html, body {
    height: 99%!important;
    page-break-after: avoid!important;
    page-break-before: avoid!important;
  }
}
</style>
