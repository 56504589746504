<template>
  <div>
    <template v-if="params.data.Opinion">
      <template v-if="can_change(params.value)">
        <fa-icon @click="editItem(params)" class="mr-1" icon="edit"/>
        <fa-icon v-if="!params.data.deleted" @click="removeItem(params)" icon="trash-alt"/>
        <fa-icon v-if="!params.data.deleted" @click="openClassificatorDoc(params.data)" :class="`ml-1 ${params.data.Changes ? 'hasNote' : ''}`"
                 icon="print"/>
        <fa-icon @click="getNumbersInfo(params.data)" v-if="params.data.CheckNoEdited" class="ml-1" icon="paste"/>
        <fa-icon @click="openHistory(params.data)" class="ml-1" icon="info-circle" />
      </template>
      <template v-else-if="params.data.manuallyRestored">
        <fa-icon @click="editItem(params)" class="mr-1" icon="edit"/>
        <fa-icon style="color: #39a164;" icon="check-circle"/>
        <fa-icon @click="openClassificatorDoc(params.data)" :class="`ml-1 ${params.data.Changes ? 'hasNote' : ''}`"
                 icon="print"/>
        <fa-icon @click="getNumbersInfo(params.data)" v-if="params.data.CheckNoEdited" class="ml-1" icon="paste"/>
        <fa-icon @click="openHistory(params.data)" class="ml-1" icon="info-circle" />
      </template>
      <div class="text-center" v-else>
        <fa-icon  @click="askToEdit(params)" class="mr-1" icon="edit"/>
        <fa-icon style="color: #39a164;" icon="check-circle"/>
        <fa-icon @click="openClassificatorDoc(params.data)" :class="`ml-1 ${params.data.Changes ? 'hasNote' : ''}`"
                 icon="print"/>
        <fa-icon @click="getNumbersInfo(params.data)" v-if="params.data.CheckNoEdited" class="ml-1" icon="paste"/>
        <fa-icon @click="openHistory(params.data)" class="ml-1" icon="info-circle" />
      </div>
    </template>

    <template v-else>
      <template v-if="can_change_opinion(params.value)">
        <fa-icon @click="editItem(params)" class="mr-1" icon="edit"/>
        <fa-icon v-if="params.data.createdBy = user.id" @click="removeItem(params)" icon="trash-alt"/>
        <fa-icon v-if="!params.data.deleted"  @click="openClassificatorDoc(params.data)" :class="`ml-1 ${params.data.Changes ? 'hasNote' : ''}`"
                 icon="print"/>
        <fa-icon @click="getNumbersInfo(params.data)" v-if="params.data.CheckNoEdited" class="ml-1" icon="paste"/>
        <fa-icon @click="openHistory(params.data)" class="ml-1" icon="info-circle" />
      </template>
      <template v-else-if="params.data.manuallyRestored">
        <fa-icon @click="editItem(params)" class="mr-1" icon="edit"/>
        <fa-icon style="color: #39a164;" icon="check-circle"/>
        <fa-icon @click="openClassificatorDoc(params.data)" :class="`ml-1 ${params.data.Changes ? 'hasNote' : ''}`"
                 icon="print"/>
        <fa-icon @click="getNumbersInfo(params.data)" v-if="params.data.CheckNoEdited" class="ml-1" icon="paste"/>
        <fa-icon @click="openHistory(params.data)" class="ml-1" icon="info-circle" />
      </template>
      <div class="text-center" v-else>
        <fa-icon @click="askToEdit(params)" class="mr-1" icon="edit"/>
        <fa-icon style="color: #39a164;" icon="check-circle"/>
        <fa-icon @click="openClassificatorDoc(params.data)" :class="`ml-1 ${params.data.Changes ? 'hasNote' : ''}`"
                 icon="print"/>
        <fa-icon @click="getNumbersInfo(params.data)" v-if="params.data.CheckNoEdited" class="ml-1" icon="paste"/>
        <fa-icon @click="openHistory(params.data)" class="ml-1" icon="info-circle" />
      </div>
    </template>
  </div>
</template>

<script>


import techServiceModule from '@/store/tech-service/techServiceModule'
import store from '@/store'

export default {
  name: 'cell-renderer-can-edit',
  data() {
    return {
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
      selectedCheckupId: null,
      selectedCheckup: null,
      selectedCheckupNumbers: [],
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    can_change() {
      return (value) => {
        const l_day = new Date(value)
        const last_day = new Date(l_day.getTime() + 10*60000)
        const now = new Date(Date.now())
        let diff = (last_day.getTime() - now.getTime()) / 1000
        diff /= 60
        return (diff > 0)
      }
    },
    canAskToEdit() {
      return (value) => {
        const last_day = new Date(value)
        last_day.setDate(last_day.getDate() + 1)
        const now = new Date(Date.now())
        let diff = (last_day.getTime() - now.getTime()) / 1000
        diff /= 60
        return (diff > 0)
      }
    },
    can_change_opinion() {
      return (value) => {
        const last_day = new Date(value)
        last_day.setDate(last_day.getDate() + 20)
        last_day.setHours(23, 59, 59, 59)
        const now = new Date(Date.now())
        let diff = (last_day.getTime() - now.getTime()) / 1000
        diff /= 60
        return true
        return (diff > 0)
      }
    },
  },
  created() {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
  },
  methods: {

    editItem(params) {
      const data = params.data
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/OPEN_EDIT_FORM', data)
    },

    getTodayCheckupsCount () {
      let todayStart = new Date()
      todayStart.setHours(0, 0, 0, 0)
      let todayEnd = new Date()
      todayEnd.setHours(23, 59, 59, 59)
      const payload = {
        start: todayStart,
        end: todayEnd
      }
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getTodayCheckupsCount', this.serialize(payload))
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getTodayOfflineCheckupsCount')
    },

    serialize(obj) {
      let str = []
      for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
          if (typeof obj[p] !== 'undefined' && obj[p] !== null && obj[p] !== '') {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
          }
        }
      }
      return str.join('&')
    },

    removeItem(params) {
      this.$swal({
        title: 'Հեռացնե՞լ',
        text: '',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Այո',
        cancelButtonText: 'Ոչ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/removeCheckup', params.data)
                  .then((response) => {
                    // this.$socket.emit('OPERATOR_REMOVED_CHECKUP', response.data.item)
                    this.getTodayCheckupsCount()
                  })
                  .catch(() => {
                    this.$swal({
                      title: 'Գործողությունը մերժվել է',
                      text: 'Ստուգեք Ձեր ինտերնետ կապի առկայությունը!',
                      icon: 'error',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    })
                  })
            }
          })
    },

    openClassificatorDoc(data) {
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_SELECTED_CHECKUP', data)
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/OPEN_DOC', 'classificator')
    },

    askToEdit(params) {
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_ASK_TO_EDIT_ITEM', params.data)
      this.$bvModal.show('modal-ask-to-edit')
    },

    getNumbersInfo (checkup) {
      this.$vs.loading()
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getCheckupNumbers', checkup._id)
          .then((response) => {
            this.$vs.loading.close()
            this.$bvModal.show('modal-add-c1-number')
          })
          .catch(error => {
            this.$vs.loading.close()
            this.$root.$emit('getError', error)
          })
    },

    openHistory (checkup) {
      this.$vs.loading()
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getCheckupHistory', checkup._id)
          .then(() => {
            this.$vs.loading.close()
            this.$bvModal.show('modal-checkup-history')
          })
          .catch(error => {
            this.$vs.loading.close()
            this.$root.$emit('getError', error)
          })
    }
  }
}
</script>
