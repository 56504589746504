<template>
  <b-card-code v-if="$checkPermission($options.name, 'view')" id="tech-service-all" no-body>

    <template v-if="docs.classificator">
      <Classificator/>
    </template>

    <template v-else-if="docs.financial">
      <Financial/>
    </template>

    <template v-else-if="docs.info">
      <Info/>
    </template>

    <template v-else-if="docs.individual">
      <Individual/>
    </template>

    <template v-else-if="docs.company">
      <Company/>
    </template>

    <template v-else-if="docs.entrepreneur">
      <Entrepreneur/>
    </template>

    <template v-else-if="docs.amounts">
      <Amounts/>
    </template>

    <template v-else-if="docs.count">
      <Count/>
    </template>

    <template v-else-if="docs.operators">
      <Operators/>
    </template>

    <template v-else>
      <b-row class="pageContent">
        <b-col md="12">
          <div class="filters">

            <div class="filter-column">
              <v-select
                  @input="getCheckups"
                  placeholder="Եզրակացություն"
                  id="vue-select-opinion"
                  :reduce="prefix => prefix.id"
                  v-model="filters.Opinion"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="opinions"
                  :clearable="false"
                  class="opinionFilter"
              />

            </div>

            <div class="filter-column">
              <b-form-input
                  autocomplete="new_owner_name"
                  @change="searchCheckup"
                  @keydown="disableSymbol($event)"
                  v-model="filters.search"
                  id="owner_name"
                  type="text"
                  placeholder="Փնտրել..."
                  class="w-full text-uppercase"
                  clearable
              />
            </div>

          </div>
        </b-col>
        <b-col md="12">
          <ag-grid-vue
              @gridReady="onGridReady"
              ref="agGridTable"
              :localeText="localization"
              :components="components"
              :gridOptions="gridOptions"
              :class="table_class"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="checkups"
              rowSelection="multiple"
              colResizeDefault="shift"
              :animateRows="true"
              :floatingFilter="true"
              :pagination="true"
              :paginationPageSize="50"
              :suppressPaginationPanel="true"
              :enableRtl="$vs.rtl">
          </ag-grid-vue>
          <b-card-body class="table-footer d-flex justify-content-between flex-wrap pt-0 pb-0 align-items-center">

            <!-- page length -->
            <strong class="black">
              {{ pagination.total }} արդյունք
            </strong>

            <!-- pagination -->
            <div>
              <b-pagination
                  @input="changePage"
                  v-model="pagination.current_page"
                  :total-rows="pagination.total"
                  :per-page="pagination.count"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-col>
      </b-row>
    </template>

    <b-modal
        id="modal-add-c1-number"
        :no-close-on-backdrop="true"
        centered
        size="sm"
        title="Կտրոնի կրկնօրինակ"
        :is-valid="false"
    >

      <b-form>
        <b-form-group>

          <div class="position-relative table-responsive">
            <table role="table" aria-busy="false" aria-colcount="7" class="table b-table table-striped table-hover"
                   id="__BVID__139"><!----><!---->
              <thead role="rowgroup" class=""><!---->
              <tr role="row" class="">
                <th role="columnheader" scope="col" aria-colindex="2" class="">
                  <div>Ամսաթիվ</div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="3" class="">
                  <div>Կտրոնի համար</div>
                </th>
              </tr>
              </thead>
              <tbody role="rowgroup"><!---->

              <tr v-for="num in checkupNumbers" role="row" class="">
                <td aria-colindex="6" role="cell" class="">{{ num.createdAt | formatDate }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ num.number }}</td>
              </tr>
              </tbody>
            </table>
          </div>


        </b-form-group>
      </b-form>


      <template #modal-footer="{ ok, cancel, hide }">
        <span variant="info">&nbsp;</span>
      </template>


    </b-modal>

    <b-modal
        id="modal-checkup-history"
        :no-close-on-backdrop="true"
        centered
        size="lg"
        class="modal-responsive"
        title="Պատմություն"
        :is-valid="false"
    >

      <b-form>
        <b-form-group>

          <div class="position-relative table-responsive">
            <table role="table" aria-busy="false" aria-colcount="7" class="table mb-2 b-table table-striped table-hover"
                   id="__BVID__140"><!----><!---->
              <thead role="rowgroup" class=""><!---->
              <tr role="row" class="">
                <th role="columnheader" scope="col" aria-colindex="2" class="">
                  <div>Ամսաթիվ</div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="3" class="">
                  <div>Մոդերատոր</div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="3" class="">
                  <div>Գործողույուն</div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="3" class="">
                  <div>Նախկին տվյալ</div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="3" class="">
                  <div>Նոր տվյալ</div>
                </th>
              </tr>
              </thead>
              <tbody role="rowgroup"><!---->

              <tr v-for="history in checkupHistory" role="row" class="">
                <td aria-colindex="6" role="cell" class="">{{ history.createdAt | formatDate }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ (history.user) ? history.user.name : '' }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ history.note }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ history.old }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ history.new }}</td>
              </tr>
              </tbody>
            </table>
          </div>


        </b-form-group>
      </b-form>


      <template #modal-footer="{ ok, cancel, hide }">
        <span variant="info">&nbsp;</span>
      </template>


    </b-modal>

  </b-card-code>
</template>

<script>

import techServiceModule from '@/store/tech-service/techServiceModule'
import companyModule from '@/store/companies/companyModule'
import store from '@/store'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BBadge,
  BButton,
  BCardBody,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
  BAlert
} from 'bootstrap-vue'
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css'
import Form from '@/views/pages/tech-service/Form'
import CellRendererOpinion from '@/views/pages/tech-service/columns/CellRendererOpinion'
import CellRendererChanges from '@/views/pages/tech-service/columns/CellRendererChanges'
import CellRendererCanEdit from '@/views/pages/tech-service/columns/CellRendererCanEdit'
import CellRendererEdit from '@/views/pages/tech-service/columns/CellRendererEdit'
import AG_GRID_LOCALE_HY from '@core/global/ag-grid-hy'
import vSelect from 'vue-select'
import Classificator from '@/views/pages/tech-service/docs/Classificator'
import Financial from '@/views/pages/tech-service/docs/Financial'
import Info from '@/views/pages/tech-service/docs/Info'
import Individual from '@/views/pages/tech-service/docs/Individual'
import Entrepreneur from '@/views/pages/tech-service/docs/Entrepreneur'
import Company from '@/views/pages/tech-service/docs/Company'
import Amounts from '@/views/pages/tech-service/docs/Amounts'
import Count from '@/views/pages/tech-service/docs/Count'
import Operators from '@/views/pages/tech-service/docs/Operators'
import carsModule from '@/store/cars/cars'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import CellRendererStatus from '@/views/pages/tech-service/columns/CellRendererStatus'

export default {
  name: 'tech-service-company-view',

  components: {
    vSelect,
    BCardCode,
    AgGridVue,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BForm,
    BRow,
    BCol,
    BTable,
    BAlert,
    Form,
    CellRendererOpinion,
    CellRendererChanges,
    CellRendererCanEdit,
    CellRendererEdit,
    Classificator,
    Financial,
    Info,
    Individual,
    Entrepreneur,
    Company,
    Amounts,
    Count,
    Operators,
    CellRendererStatus
  },

  data() {
    return {
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
      COMPANIES_STORE_MODULE_NAME: 'companiesModule',
      CARS_STORE_MODULE_NAME: 'carsModule',
      gridOptions: {
        getRowStyle: (params) => {
          if (params.data.CheckNoEdited) {
            return { 'background-color': '#ffc107' }
          }
          if (params.data.deleted) {
            return { 'background-color': '#afafaf' }
          }
        }
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      components: null,
      opinions: [
        {
          label: 'Սարքին',
          id: true
        },
        {
          label: 'Անսարք',
          id: false
        }
      ]
    }
  },

  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    let todayStart = new Date()
    todayStart.setHours(0, 0, 0, 0)

    let todayEnd = new Date()
    todayEnd.setHours(23, 59, 59, 59)

    const initialFilters = {
      Opinion: true,
      startDate: todayStart,
      endDate: todayEnd,
      search: '',
      company: '',
      fields: {}
    }

    return {
      skin,
      isDark,
      initialFilters
    }
  },

  computed: {

    checkupNumbers() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].checkupNumbers
    },

    checkupHistory() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].checkupHistory
    },

    columnDefs() {
      return [
        {
          headerName: 'Տեխզննում',
          children: [
            {
              headerName: 'Կարգավիճակ',
              field: 'createdAt',
              width: 270,
              cellRendererFramework: 'CellRendererCanEdit'
            },
            {
              headerName: 'Գույքահարկ',
              field: 'inspectionStatus',
              filter: true,
              width: 180,
              cellRendererFramework: 'CellRendererStatus'
            },
            {
              headerName: 'ԴԱՀԿ',
              field: 'dahk_exists',
              filter: false,
              width: 150,
              cellRendererFramework: 'CellRendererStatus'
            },
            {
              headerName: 'Ստեղծվել է',
              field: 'createdAt',
              filter: true,
              floatingFilterComponent: 'customDateFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'createdAt',
                className: ''
              },
              width: 150,
              valueFormatter: this.dateFormatter
            },
            {
              headerName: 'Մեքենա',
              field: 'ownerStatus.Owner',
              width: 180,
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'car',
                className: ''
              },
              valueFormatter: this.carFormatter
            },
            {
              headerName: 'Համարանիշ',
              field: 'CarRegNo',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'CarRegNo',
                className: 'text-uppercase'
              },
              width: 150,
              valueFormatter: this.carRegNoFormatter
            },
            {
              headerName: 'Փաստաթուղթ',
              field: 'documentNumber',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'documentNumber',
                className: 'text-uppercase'
              },
              width: 140,
              valueFormatter: this.uppercaseFormatter
            },
            {
              headerName: 'Նույնացման (VIN) համարը',
              field: 'VIN',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'VIN',
                className: 'text-uppercase'
              },
              width: 210,
            },
            {
              headerName: 'Հաջորդ Տ․Զ․',
              field: 'NextCheckup',
              filter: true,
              floatingFilterComponent: 'customSelectFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'NextCheckup',
                className: '',
                options: [
                  {
                    value: 0,
                    label: '6 ամսից'
                  },
                  {
                    value: 1,
                    label: '1 տարուց'
                  },
                  {
                    value: 2,
                    label: '2 տարուց'
                  }
                ]
              },
              width: 160,
              valueFormatter: this.nextCheckupFormatter
            },
            // {
            //   headerName: 'Թափքի տեսակ',
            //   field: 'body',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'body',
            //     className: ''
            //   },
            //   width: 150,
            //   valueFormatter: this.bodyNameFormatter,
            // },
            // {
            //   headerName: 'Գույն',
            //   field: 'color',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'color',
            //     className: ''
            //   },
            //   width: 180,
            // }
          ]
        },
        {
          headerName: 'Տեխնիկական զննության կայան',
          children: [
            {
              headerName: 'Օպերատոր',
              field: 'createdBy.name',
              width: 150,
              filter: true,
              floatingFilterComponent: 'customSelectFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'createdBy',
                className: '',
                options: this.company_operators
              },
            },
            {
              headerName: 'Անվանում',
              field: 'company.name',
              width: 150,
              hide: false
            },
            {
              headerName: 'Տ․Զ․Կ հասցե',
              field: 'company',
              valueFormatter: this.companyAddressFormatter,
              width: 200,
              hide: false
            },
          ]
        },
        {
          headerName: 'Տրանսպորտային միջոցի տնօրինող',
          children: [
            {
              headerName: 'Տնօրինող',
              field: 'ownerStatus.Owner',
              width: 200,
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'ownerStatus.Owner',
                className: ''
              },
            },
            {
              headerName: 'ՀՎՀՀ',
              field: 'ownerStatus.Notes',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'ownerStatus.Notes',
                className: ''
              },
              width: 130,
            },
            {
              headerName: 'Հեռախոս',
              field: 'Phone',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'Phone',
                className: ''
              },
              width: 130,
            },
            {
              headerName: 'Մարզ',
              field: 'region',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'region',
                className: ''
              },
              width: 180,
            },
            {
              headerName: 'Քաղաք',
              field: 'county',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'county',
                className: ''
              },
              width: 180,
            },
            {
              headerName: 'Փողոց',
              field: 'street',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'street',
                className: ''
              },
              width: 210,
            },
            {
              headerName: 'Հասցե',
              field: 'address',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'address',
                className: ''
              },
              width: 150,
            },
            {
              headerName: 'Գրանցման փաստաթուղթ',
              field: 'documentNumber',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'documentNumber',
                className: 'text-uppercase'
              },
              width: 210,
              valueFormatter: this.uppercaseFormatter
            },
          ]
        },
        {
          headerName: 'Տրանսպորտային միջոց',
          children: [
            {
              headerName: 'Խումբ',
              field: 'car_group',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'car_group',
                className: ''
              },
              width: 210,
            },
            {
              headerName: 'Տեսակ',
              field: 'car_type',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'car_type',
                className: ''
              },
              width: 210,
            },
            {
              headerName: 'Մեքենա',
              field: 'car',
              valueFormatter: this.carFormatter,
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'car',
                className: ''
              },
              width: 210,
            },
            {
              headerName: 'Մակնիշ',
              field: 'car',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'car',
                className: ''
              },
              width: 210,
            },
            {
              headerName: 'Մոդել',
              field: 'car_model',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'car_model',
                className: ''
              },
              width: 210,
            },
            {
              headerName: 'Թողարկման տարեթիվ',
              field: 'MadeAt',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'MadeAt',
                className: ''
              },
              width: 210,
            },
            {
              headerName: 'Գրանցման համարանիշ',
              field: 'CarRegNo',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'CarRegNo',
                className: 'text-uppercase'
              },
              width: 210,
              valueFormatter: this.carRegNoFormatter
            },
            {
              headerName: 'Վառելիքի տեսակ',
              field: 'fuel',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'fuel',
                className: ''
              },
              width: 210,
            },
            // {
            //   headerName: 'Թափքի տեսակ',
            //   field: 'body',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'body',
            //     className: ''
            //   },
            //   width: 210,
            //   valueFormatter: this.bodyNameFormatter,
            // },
            // {
            //   headerName: 'Գույն',
            //   field: 'color',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'color',
            //     className: ''
            //   },
            //   width: 210,
            // },
            {
              headerName: 'Նույնացման (VIN) համարը',
              field: 'VIN',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'VIN',
                className: 'text-uppercase'
              },
              width: 210,
            },
            // {
            //   headerName: 'Շարժիչի համար',
            //   field: 'EngineNo',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'EngineNo',
            //     className: ''
            //   },
            //   width: 210,
            // },
            // {
            //   headerName: 'Հենասարքի համար',
            //   field: 'TrilerNo',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'TrilerNo',
            //     className: ''
            //   },
            //   width: 210,
            // },
            // {
            //   headerName: 'Թափքի համար',
            //   field: 'BodyNo',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'BodyNo',
            //     className: ''
            //   },
            //   width: 210,
            // },
            // {
            //   headerName: 'Գազ․ վեր․ ակտ N',
            //   field: 'GasNo',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'GasNo',
            //     className: ''
            //   },
            //   width: 210,
            // },
            {
              headerName: 'Տրված է',
              field: 'GasDate',
              filter: true,
              floatingFilterComponent: 'customDateFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'GasDate',
                className: ''
              },
              width: 210,
              valueFormatter: this.dayFormatter
            },
          ]
        },
        {
          headerName: 'Կառուցվածքային փոփոխություն',
          children: [
            {
              headerName: 'Առկա է',
              field: 'Changes',
              filter: true,
              floatingFilterComponent: 'customSelectFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'Changes',
                className: '',
                options: [
                  {
                    value: true,
                    label: 'Առկա է'
                  },
                  {
                    value: false,
                    label: 'Առկա չէ'
                  }
                ]
              },
              width: 115,
              cellRendererFramework: 'CellRendererChanges'
            },
            {
              headerName: 'Փոփոխություն',
              field: 'ChangesNotes',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'ChangesNotes',
                className: ''
              },
              width: 210,
            },
          ]
        },
        {
          headerName: 'Տեխնիկական զննություն',
          children: [
            {
              headerName: 'Կտրոնի համար',
              field: 'CheckNo',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'CheckNo',
                className: ''
              },
              width: 210,
            },
            {
              headerName: 'Նշումներ',
              field: 'CheckNotes',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'CheckNotes',
                className: ''
              },
              width: 210,
            },
            {
              headerName: 'ՏԶ կտրոնի տրման ամսաթիվ',
              field: 'EkoDate',
              filter: true,
              floatingFilterComponent: 'customDateFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'EkoDate',
                className: ''
              },
              width: 210,
              valueFormatter: this.dayFormatter
            },
            {
              headerName: 'Եզրակացություն',
              field: 'Opinion',
              filter: true,
              floatingFilterComponent: 'customSelectFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'Opinion',
                className: '',
                options: [
                  {
                    value: true,
                    label: 'Սարքին'
                  },
                  {
                    value: false,
                    label: 'Անսարք'
                  }
                ]
              },
              width: 180,
              cellRendererFramework: 'CellRendererOpinion'
            },
            {
              headerName: 'Պատճառը',
              field: 'OpinionNotes',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'OpinionNotes',
                className: ''
              },
              width: 210,
            },
            {
              headerName: 'Հաջորդ Տ․Զ․',
              field: 'NextCheckup',
              filter: true,
              floatingFilterComponent: 'customSelectFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'NextCheckup',
                className: '',
                options: [
                  {
                    value: 0,
                    label: '6 ամսից'
                  },
                  {
                    value: 1,
                    label: '1 տարուց'
                  },
                  {
                    value: 2,
                    label: '2 տարուց'
                  }
                ]
              },
              width: 160,
              valueFormatter: this.nextCheckupFormatter
            },
            {
              headerName: 'Կրկնակի Տ․Զ․',
              field: 'DoubleChecked',
              filter: true,
              floatingFilterComponent: 'customSelectFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'DoubleChecked',
                className: '',
                options: [
                  {
                    value: true,
                    label: 'Այո'
                  },
                  {
                    value: false,
                    label: 'Ոչ'
                  },
                ]
              },
              width: 140,
              cellRendererFramework: 'CellRendererChanges'
            },
          ]
        },
        // {
        //   headerName: 'Վճարումներ',
        //   children: [
        //     // {
        //     //   headerName: 'Բն․ անդ․ N',
        //     //   field: 'EkoNo',
        //     //   filter: true,
        //     //   floatingFilterComponent: 'customFloatingFilter',
        //     //   floatingFilterComponentParams: {
        //     //     suppressFilterButton: true,
        //     //     field: 'EkoNo',
        //     //     className: ''
        //     //   },
        //     //   width: 210,
        //     // },
        //     {
        //       headerName: 'Գումար',
        //       field: 'EkoSum',
        //       filter: true,
        //       floatingFilterComponent: 'customFloatingFilter',
        //       floatingFilterComponentParams: {
        //         suppressFilterButton: true,
        //         field: 'EkoSum',
        //         className: ''
        //       },
        //       width: 210,
        //     },
        //     {
        //       headerName: 'Տ․Զ․ վճար',
        //       field: 'Sum',
        //       filter: true,
        //       floatingFilterComponent: 'customFloatingFilter',
        //       floatingFilterComponentParams: {
        //         suppressFilterButton: true,
        //         field: 'Sum',
        //         className: ''
        //       },
        //       width: 210,
        //     }
        //   ]
        // }
      ]
    },

    company_operators() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].company_operators.map(e => ({
        label: e.name,
        value: e._id,
      }))
    },

    edit() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].edit
    },

    bodies() {
      return this.$store.state[this.CARS_STORE_MODULE_NAME].bodies
    },

    docs() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].docs
    },

    checkups() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].checkups
    },

    pagination() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].pagination
    },

    filters: {
      get () {
        return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].filters
      },
      set (data) {
        this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_INITIAL_FILTERS', data)
      }
    },

    table_class() {
      return (this.isDark) ? 'tech-service-table ag-theme-alpine-dark w-100 ag-grid-table' : 'tech-service-table ag-theme-alpine w-100 ag-grid-table'
    },

    localization() {
      return AG_GRID_LOCALE_HY
    },

    companies() {
      return [
        {
          label: 'Կազմակերպություն',
          value: null,
          disabled: true
        },
        ...this.$store.state[this.COMPANIES_STORE_MODULE_NAME].companies.map(e => ({
          label: e.name,
          id: e._id
        }))
      ]
    },

    ownerStatuses() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].ownerStatuses
    },

    nextCheckups() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].nextCheckups
    },

  },

  beforeMount() {
    this.components = {
      customFloatingFilter: this.getCustomFloatingFilterComponent(),
      customDateFloatingFilter: this.getCustomDateFloatingFilterComponent(),
      customSelectFloatingFilter: this.getCustomSelectFloatingFilterComponent(),
    }
  },

  mounted() {
  },

  created() {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
    if (!store.hasModule(this.COMPANIES_STORE_MODULE_NAME)) store.registerModule(this.COMPANIES_STORE_MODULE_NAME, companyModule)
    if (!store.hasModule(this.CARS_STORE_MODULE_NAME)) store.registerModule(this.CARS_STORE_MODULE_NAME, carsModule)
    this.getCheckups()
    this.getOperators()
    this.getCompanies()
    this.getBodies()
    this.filters = this.initialFilters
  },

  destroyed() {
    this.filters = this.initialFilters
  },

  methods: {

    getOperators() {
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getOperators')
    },

    getBodies() {
      return this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getBodies')
    },

    getCompanies() {
      this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/getCompanies')
    },

    onGridReady(params) {
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_GRID_API', params.api)
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_GRID_COLUMN_API', params.columnApi)
    },

    getCheckups() {
      this.$vs.loading()
      const filters = JSON.parse(JSON.stringify(this.filters))
      const { fields } = filters
      delete filters.fields
      filters.columnFilter = JSON.stringify(fields)
      const filterData = {
        current_page: this.pagination.current_page,
        ...filters
      }
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getCompanyCheckups', this.serialize(filterData))
          .then(() => {
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.$root.$emit('getError', error)
            this.$vs.loading.close()
          })
    },

    serialize(obj) {
      let str = []
      for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
          if (typeof obj[p] !== 'undefined' && obj[p] !== null && obj[p] !== '') {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
          }
        }
      }
      return str.join('&')
    },

    changePage(page) {
      this.pagination.current_page = page
      this.getCheckups()
    },

    disableSymbol(e) {
      if (!(/^[*a-zA-Z0-9]*$/.test(e.key))) {
        e.preventDefault()
      }
    },

    changeCompany() {
      this.getCheckups()
    },

    searchCheckup() {
      this.getCheckups()
    },

    ownerStatusFormatter(params) {
      const index = this.ownerStatuses.findIndex((i) => i.id === params.value)
      if (index >= 0) {
        return this.ownerStatuses[index].title
      }
      return ''
    },

    companyAddressFormatter(params) {
      return params.value.region + ' ' + params.value.county + ' ' + params.value.street + ' ' + params.value.address
    },

    carFormatter(params) {
      return params.data.car + ' ' + params.data.car_model
    },

    carRegNoFormatter(params) {
      return `${params.data.CarRegNo} ${(params.data.YellowCarRegNo) ? `(${params.data.YellowCarRegNo.toLocaleUpperCase()})` : ''}`
    },

    bodyNameFormatter(params) {
      if (params.value) {
        return params.value
      } else {
        let bodyIndex = this.bodies.findIndex((b) => b.id === params.data.body_id)
        if (bodyIndex >= 0) {
          return this.bodies[bodyIndex].name
        }
      }
    },

    uppercaseFormatter(params) {
      return (params.value) ? params.value.toLocaleUpperCase() : params.value
    },

    dateFormatter(params) {
      return this.$options.filters.formatDate(params.value)
    },

    dayFormatter(params) {
      return this.$options.filters.formatDay(params.value)
    },

    nextCheckupFormatter(params) {
      const index = this.nextCheckups.findIndex((e) => e.value == params.value)
      if (index >= 0) {
        return this.nextCheckups[index].label
      }
      return params.value
    },

    appendFilter(field, value) {
      this.filters.fields[field] = value
      this.getCheckups()
    },

    appendDateFilter(field, value) {
      let start = new Date(value)
      start.setHours(0, 0, 0, 0)
      let end = new Date(value)
      end.setHours(23, 59, 59, 59)
      if (field === 'createdAt') {
        this.filters.startDate = start
        this.filters.endDate = end
      }
      this.getCheckups()
    },

    getCustomFloatingFilterComponent() {
      const methods = this

      function NumberFloatingFilter() {
      }

      NumberFloatingFilter.prototype.init = function (params) {
        this.eGui = document.createElement('div')
        this.eGui.innerHTML = '<input class="ag-input-field-input ag-text-field-input ' + params.className + '" type="text" />'
        this.currentValue = null
        this.eFilterInput = this.eGui.querySelector('input')
        const that = this
        const onInputBoxChanged = () => {
          that.currentValue = that.eFilterInput.value
          methods.appendFilter(params.field, that.currentValue)
        }
        this.eFilterInput.addEventListener('change', onInputBoxChanged)
      }
      NumberFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
        if (!parentModel) {
          this.eFilterInput.value = ''
          this.currentValue = null
        } else {
          this.eFilterInput.value = parentModel.filter + ''
          this.currentValue = parentModel.filter
        }
      }
      NumberFloatingFilter.prototype.getGui = function () {
        return this.eGui
      }
      return NumberFloatingFilter
    },

    getCustomSelectFloatingFilterComponent() {
      const methods = this

      function NumberFloatingFilter() {
      }

      NumberFloatingFilter.prototype.init = function (params) {
        this.eGui = document.createElement('div')
        let select = '<select class="ag-input-field-input ag-text-field-input ' + params.className + '">'
        select += '<option value=""></option>'
        if (params.options && params.options.length > 0) {
          params.options.map((item) => {
            select += `<option value="${item.value}">${item.label}</option>`
          })
        }
        select += '</select>'
        this.eGui.innerHTML = select
        this.currentValue = null
        this.eFilterInput = this.eGui.querySelector('select')
        const that = this
        const onInputBoxChanged = () => {
          that.currentValue = that.eFilterInput.value
          methods.appendFilter(params.field, that.currentValue)
        }
        this.eFilterInput.addEventListener('change', onInputBoxChanged)
      }
      NumberFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
        if (!parentModel) {
          this.eFilterInput.value = ''
          this.currentValue = null
        } else {
          this.eFilterInput.value = parentModel.filter + ''
          this.currentValue = parentModel.filter
        }
      }
      NumberFloatingFilter.prototype.getGui = function () {
        return this.eGui
      }
      return NumberFloatingFilter
    },

    getCustomDateFloatingFilterComponent() {
      const methods = this

      function NumberFloatingFilter() {
      }

      NumberFloatingFilter.prototype.init = function (params) {
        this.eGui = document.createElement('div')
        this.eGui.className = 'w-100'
        this.eGui.innerHTML = '<input data-date-format="DD/MM/YYYY" class="ag-input-field-input ag-text-field-input ' + params.className + '" type="date" />'
        this.currentValue = null
        this.eFilterInput = this.eGui.querySelector('input')
        const that = this
        const onInputBoxChanged = () => {
          that.currentValue = that.eFilterInput.value
          methods.appendDateFilter(params.field, that.currentValue)
        }
        this.eFilterInput.addEventListener('change', onInputBoxChanged)
      }
      NumberFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
        if (!parentModel) {
          this.eFilterInput.value = ''
          this.currentValue = null
        } else {
          this.eFilterInput.value = parentModel.filter + ''
          this.currentValue = parentModel.filter
        }
      }
      NumberFloatingFilter.prototype.getGui = function () {
        return this.eGui
      }
      return NumberFloatingFilter
    },

  }

}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#tech-service-all {
  .tech-service-table {
    max-height: calc(var(--vh, 1vh) * 100 - 13rem);
    min-height: calc(var(--vh, 1vh) * 100 - 13rem);
    height: calc(var(--vh, 1vh) * 100 - 13rem);
    padding: 0.5rem;
  }

  .table-footer {
    padding-bottom: 10px !important;
  }
}
</style>
