<template>
  <b-badge class="w-100" :variant="color(params)">
    <fa-icon :icon="icon(params)" />
    <span style="    font-size: 9px;" v-if="params.colDef.field === 'inspectionStatus'">
      {{ params.data.recording_date }}
    </span>
    <span style="    font-size: 9px;" v-if="['ADMIN', 'VIEWER'].includes(userType) && params.colDef.field === 'inspectionStatus'">
      ({{ params.data.taxDebt }})
    </span>
  </b-badge>
</template>

<script>

import { BBadge } from 'bootstrap-vue'
import VueCountdown from '@chenfengyuan/vue-countdown';
import store from '@/store'
import techServiceModule from '@/store/tech-service/techServiceModule'

export default {
  name: 'cell-renderer-can-edit',
  components: {
    BBadge,
    VueCountdown
  },
  data() {
    return {
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
    }
  },
  computed: {

    userType () {
      return atob(this.$store.state.user_type)
    },

    color () {
      return (item) => {
        if(item.value === false) {
          return 'danger'
        } else if (item.value === true) {
          return 'success'
        } else {
          return 'warning'
        }
      }
    },
    icon() {
      return (item) => {
        if(item.value === false) {
          return 'times-circle'
        } else if (item.value === true) {
          return 'check-circle'
        } else {
          return 'info-circle'
        }
      }
    }

  }
}
</script>
