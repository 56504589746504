<template>
  <b-card-body class="pb-0">

    <div class="doc-header text-center">
      <span class="float-left"> Ձև 1</span>
      <strong>ՏՐԱՆՍՊՈՐՏԱՅԻՆ ՄԻՋՈՑՆԵՐԻ ՏԵԽՆԻԿԱԿԱՆ ՎԻՃԱԿԻ ԱՐԱՏՈՐՈՇՄԱՆ ՔԱՐՏ</strong>
      <div class="qr-code">
        <vue-qrcode :width="120" :value="qr_code_content" />
      </div>
    </div>
    <div class="doc-header-content mt-1">
      <b-row>
        <b-col cols="5">Տեխնիկական զննության կայանի անվանումը</b-col>
        <b-col cols="7">{{invoiceData.company.name}}</b-col>
        <b-col cols="5">Գտնվելու վայրը</b-col>
        <b-col cols="7">{{invoiceData.company.region}} {{invoiceData.company.county}} {{invoiceData.company.street}} {{invoiceData.company.address}}</b-col>
      </b-row>
    </div>

    <hr class="invoice-spacing header-spacing">

    <b-row>
      <b-col class="pr-0" cols="3">Տեխնիկական զննության ամսաթիվը</b-col>
      <b-col class="pl-0" cols="3"><strong>{{invoiceData.createdAt | formatDate}}</strong></b-col>

      <b-col class="pr-0" cols="3">Թողարկման տարեթիվ</b-col>
      <b-col class="pl-0" cols="3"><strong>{{invoiceData.MadeAt}}</strong></b-col>

      <b-col class="pr-0"  cols="3">Տեխնիկական զննության փուլը</b-col>
      <b-col class="pl-0"  cols="3"><strong>{{double_checked_name(invoiceData.DoubleChecked)}}</strong></b-col>

      <b-col class="pr-0"  cols="3">Վառելիք</b-col>
      <b-col class="pl-0"  cols="3"><strong>{{invoiceData.fuel}}</strong></b-col>

      <b-col class="pr-0"  cols="3">Մակնիշը</b-col>
      <b-col class="pl-0"  cols="3"><strong>{{invoiceData.car}} {{invoiceData.car_model}}</strong></b-col>

<!--      <b-col class="pr-0"  cols="3">Թափքի N</b-col>-->
<!--      <b-col class="pl-0"  cols="3"><strong>{{invoiceData.BodyNo}}</strong></b-col>-->

<!--      <b-col class="pr-0"  cols="3">Թափքի տեսակ</b-col>-->
<!--      <b-col class="pl-0"  cols="3"><strong>{{bodyName(invoiceData)}}</strong></b-col>-->

<!--      <b-col class="pr-0"  cols="3">Հենասարքի N</b-col>-->
<!--      <b-col class="pl-0"  cols="3"><strong>{{invoiceData.TrilerNo}}</strong></b-col>-->

      <b-col class="pr-0"  cols="3">Համարանիշ</b-col>
      <b-col class="pl-0"  cols="3"><strong>{{invoiceData.CarRegNo}} {{ (invoiceData.YellowCarRegNo) ? `(${invoiceData.YellowCarRegNo})` : ''}}</strong></b-col>

<!--      <b-col class="pr-0"  cols="3">Շարժիչի N</b-col>-->
<!--      <b-col class="pl-0"  cols="3"><strong>{{invoiceData.EngineNo}}</strong></b-col>-->

      <b-col class="pr-0"  cols="3">Նույնականացման (VIN) համարը</b-col>
      <b-col class="pl-0"  cols="3"><strong>{{invoiceData.VIN}}</strong></b-col>

      <b-col class="pr-0"  cols="3">{{NextCheckupLabel}}</b-col>
      <b-col class="pl-0"  cols="3"><strong>{{NextCheckupName(invoiceData.NextCheckup)}}</strong></b-col>

      <b-col class="pr-0"  cols="3">Տնօրինողը և գրանցման հասցեն</b-col>
      <b-col class="pl-0"  cols="3"><strong>{{invoiceData.ownerStatus.Owner}}</strong></b-col>
      <b-col class="pr-0"  cols="6"><strong>{{invoiceData.region}} {{invoiceData.county}} {{invoiceData.street}} {{invoiceData.address}}</strong></b-col>

      <b-col class="pr-0"  cols="3">Պետական գրանցման փաստաթուղթը</b-col>
      <b-col class="pl-0"  cols="9"><strong>{{invoiceData.documentNumber}}</strong></b-col>
<!--      <b-col cols="12">Սեղմված բնական կամ հեղուկացված նավթային գազով աշխատելու համար վերասարքավորման ակտի N <strong>{{invoiceData.GasNo}}</strong>, տրված՝ <strong>{{invoiceData.GasDate | formatDay}}</strong></b-col>-->

    </b-row>

    <hr class="invoice-spacing">

    <div class="classificatorsData">
      <div class="watermark">INSPECT.AM</div>
      <div v-if="!invoiceData.newClassificator" class="classificators">
        <div v-for="classificator in classificators" class="classificator">
          <div class="title">
            <div class="name">{{classificator.name}}</div>
          </div>
          <div class="data">
            <div class="code">{{classificator.code}}</div>
            <div :class="checkbox_class(classificator.id)"></div>
          </div>
        </div>
      </div>
      <div v-else class="classificators">
        <div v-for="classificator in new_classificators" class="classificator_list">
          <div class="classificator">
            <div class="title">
              <strong>{{classificator.data.name}}</strong>
            </div>
            <div class="data">
            </div>
          </div>
          <div v-for="item in classificator.items" class="classificator">
            <div class="title">
              <div class="name">{{item.name}}</div>
            </div>
            <div class="data">
              <div class="code">{{item.code}}</div>
              <div :class="checkbox_class(item.id)"></div>
            </div>
          </div>
          <div class="classificator" v-for="empty in classificator.empties"></div>
        </div>
      </div>
    </div>

    <div class="doc-footer" style="width: 100%;">
      <b-row style="width: 100%;">
        <b-col style="width: 100%;"  md="12" col="12" class="notesContent">
          <strong style="width: 100%;" class="w-100 text-center">ԴԻՏՈՂՈՒԹՅՈՒՆՆԵՐ ՍՏՈՒԳՄԱՆ ԺԱՄԱՆԱԿ ՀԱՅՏՆԱԲԵՐՎԱԾ ԱՆՍԱՐՔՈՒԹՅՈՒՆՆԵՐԻ ՄԱՍԻՆ ԵՎ ԼՐԱՑՈՒՑԻՉ ՏԵՂԵԿՈՒԹՅՈՒՆՆԵՐ</strong>
          <p  style="width: 100%;" v-if="invoiceData.CheckNo" class="mt-1">Հատկացվել է N <strong>{{invoiceData.CheckNo}}</strong> տեխնիկական զննության կտրոնը:</p>
          <div  style="width: 100%;" class="notes w-100">

            <template v-if="invoiceData.Opinion">{{invoiceData.ChangesNotes}}</template>
            <template v-else>{{invoiceData.OpinionNotes}}</template>

          </div>
        </b-col>

        <b-col class="mt-1" col="12" md="12">

        </b-col>


        <div class="signs">
          <div class="sign">
            <div class="title">Տեխնիկական վիճակի հսկիչ՝</div>
            <div class="space"></div>
            <div class="name">{{ (invoiceData.mechanic) ? invoiceData.mechanic.name : randomMechanic}}</div>
          </div>
          <div class="sign">
            <div class="title">Տրանսպորտային միջոցը տնօրինող՝</div>
            <div class="space"></div>
            <div class="name">{{invoiceData.ownerStatus.Owner}}</div>
          </div>
        </div>


      </b-row>

    </div>


  </b-card-body>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle,
} from 'bootstrap-vue'
import VueQrcode from 'vue-qrcode'
import Logo from '@core/layouts/components/Logo'
import InvoiceSidebarSendInvoice from '@/views/pages/tech-service/docs/InvoiceSidebarSendInvoice'
import VueHtml2pdf from 'vue-html2pdf'
export default {
  name: 'classificator-content',
  props: ['invoiceData', 'randomMechanic', 'bodies'],
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    VueQrcode,
  },
  data () {
    return {
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
      CARS_STORE_MODULE_NAME: 'carsModule',
    }
  },
  computed: {
    company () {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].company
    },
    double_checked_name () {
      return (value) => {
        return (value) ? 'Կրկնակի' : 'Սկզբնական'
      }
    },
    NextCheckupLabel() {
      return this.invoiceData.Opinion ? 'Հաջորդ Տ.Զ.' : 'Ժամկետ'
    },
    NextCheckupName () {
      return (value) => {
        if (this.invoiceData.Opinion) {
          switch (value) {
            case 0:
              return '6 ամսից'
            case 1:
              return '1 տարուց'
            case 2:
              return '2 տարուց'
          }
        } else {
          return '20 օր'
        }
      }
    },
    classificators() {
      const data = (this.invoiceData.newClassificator) ? this.$store.state[this.CARS_STORE_MODULE_NAME].new_classificators : this.$store.state[this.CARS_STORE_MODULE_NAME].classificators
      return data.filter(e => e.status)
    },
    new_classificators() {
      const data = this.$store.state[this.CARS_STORE_MODULE_NAME].new_classificators.filter(e => e.status)
      const list = {}
      data.map((item, index) => {
        if (item.code % 100 === 0) {
          data.splice(index, 1)
          list[item.code] = {
            data: item,
            items: []
          }
        }
      })
      data.map(item => {
        const code = parseInt(item.code / 100) * 100
        list[code].items.push(item)
      })
      for (const [key, item] of Object.entries(list)) {
        let length = item.items.length
        let empties = 0
        if (length < 4) {
          empties = 4 - length
        } else if (length >= 5 && length < 9) {
          empties = 9 - length
        } else if (length >= 10 && length < 14) {
          empties = 14 - length
        }
        list[key].empties = empties
      }
      return list
    },
    checkbox_class () {
      return (id) => {
        return (this.invoiceData.classificators.includes(id)) ? 'checkbox active' : 'checkbox'
      }
    },
    qr_code_content () {
      const x = (this.invoiceData.documentNumber) ? this.invoiceData.documentNumber : (this.invoiceData.CarRegNo) ? this.invoiceData.CarRegNo : this.invoiceData._id
      console.log(x)
      return x
    }
  },
  methods: {
    bodyName (item) {
      if (item.body) {
        return item.body
      }
      else {
        let bodyIndex = this.bodies.findIndex((b) => b.id === item.body_id)
        if (bodyIndex >= 0) {
          return this.bodies[bodyIndex].name
        }
      }
    },
  }
}
</script>
