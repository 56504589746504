var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.userType !== 'TECHNICAL_SERVICE_OPERATOR')?[(_vm.params.data.Opinion)?_c('div',{class:("radarIcon " + ((_vm.can_edit(_vm.params)) ? 'warning' : 'success'))},[_c('span')]):_c('div',{staticClass:"radarIcon danger"},[_c('span')])]:_vm._e(),_c('b-badge',{class:_vm.badgeColClass,attrs:{"variant":_vm.color(_vm.params)}},[(_vm.userType === 'TECHNICAL_SERVICE_OPERATOR')?[(_vm.can_edit(_vm.params))?[_c('vue-countdown',{attrs:{"time":_vm.expire_time(_vm.params)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var days = ref.days;
var hours = ref.hours;
var minutes = ref.minutes;
var seconds = ref.seconds;
return [(days > 0)?_c('span',[_vm._v(_vm._s(days)+" օր․")]):_vm._e(),_vm._v(" "+_vm._s(hours)+" ժ․ "+_vm._s(minutes)+" ր․ "+_vm._s(seconds)+" վ․ ")]}}],null,false,3461555851)})]:[_vm._v(" "+_vm._s(_vm.editContent(_vm.params))+" ")]]:[(_vm.params.data.Opinion)?_c('div',[(_vm.can_edit(_vm.params))?[_vm._v(" Ստուգվում է ")]:[_vm._v(" Ստուգված է ")]],2):_c('div',[(_vm.userType === 'ADMIN' || _vm.userType === 'VIEWER')?[(_vm.can_edit(_vm.params))?[_c('vue-countdown',{attrs:{"time":_vm.expire_time(_vm.params)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var days = ref.days;
var hours = ref.hours;
var minutes = ref.minutes;
var seconds = ref.seconds;
return [(days > 0)?_c('span',[_vm._v(_vm._s(days)+" օր․")]):_vm._e(),_vm._v(" "+_vm._s(hours)+" ժ․ "+_vm._s(minutes)+" ր․ "+_vm._s(seconds)+" վ․ ")]}}],null,false,3461555851)})]:[_vm._v(" "+_vm._s(_vm.editContent(_vm.params))+" ")]]:[_vm._v(" Ժամկետանց ")]],2)]],2),(_vm.userType === 'ADMIN' || _vm.userType === 'TECHNICAL_SERVICE_OWNER' || _vm.userType === 'VIEWER')?_c('fa-icon',{class:("ml-1 printCol " + (_vm.params.data.Changes ? 'hasNote' : '')),attrs:{"icon":"print"},on:{"click":function($event){return _vm.openClassificatorDoc(_vm.params.data)}}}):_vm._e(),(_vm.userType === 'ADMIN' && ['ruben.ordanyan@gmail.com', 'khamazaspyan@gmail.com'].includes(this.$store.state.user.email))?[_c('fa-icon',{staticClass:"ml-1",attrs:{"icon":"trash-alt"},on:{"click":function($event){return _vm.removeItemSoft(_vm.params)}}}),_c('fa-icon',{staticClass:"ml-1",attrs:{"icon":"edit"},on:{"click":function($event){return _vm.editItem(_vm.params)}}})]:_vm._e(),(_vm.params.data.CheckNoEdited && _vm.userType !== 'TECHNICAL_SERVICE_OPERATOR')?_c('fa-icon',{staticClass:"ml-1",attrs:{"icon":"paste"},on:{"click":function($event){return _vm.getNumbersInfo(_vm.params.data)}}}):_vm._e(),(_vm.userType === 'ADMIN' || _vm.userType === 'TECHNICAL_SERVICE_OWNER' || _vm.userType === 'VIEWER')?_c('fa-icon',{staticClass:"ml-1",attrs:{"icon":"history"},on:{"click":function($event){return _vm.openHistory(_vm.params.data)}}}):_vm._e(),(_vm.userType === 'VIEWER')?_c('fa-icon',{staticClass:"ml-1",attrs:{"icon":"info-circle"},on:{"click":function($event){return _vm.openCompanyInfo(_vm.params.data)}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }